import { useEffect } from 'react';
import Head from 'next/head'
import { Flex, Box } from "@chakra-ui/react"
import Footer from './Footer'
import Header from './Navbar';
import DemoAccountInfo from './DemoAccountInfo'
import { useAuth } from '@clerk/nextjs';


const name = 'Berk Sozer'
export const siteTitle = 'Biocodify'

export default function Layout({ children }) {
  const { isLoaded, userId, sessionId, getToken } = useAuth();

  return (
    <div>
      <Head>        
        {/* <link rel="icon" href="/favicon.ico" /> */}
        {/* <link rel="manifest" href="/favicon/site.webmanifest" /> */}
        {/* Global Site Tag (gtag.js) - Google Analytics */}

        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
      </Head>
      <Flex minH='100vh' flexDirection="column" margin="0 auto">
        <Header />
        {userId == process.env.NEXT_PUBLIC_DEMO_USER_ID && <DemoAccountInfo />}
        {children}
        <Box marginTop="auto">
        <Footer />
        </Box>
      </Flex>
      {/* {!home && (
        <div>
          <Link href="/">
            <a>← Back to home</a>
          </Link>
        </div>
      )} */}
    </div>
  )
}