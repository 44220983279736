import React from 'react'
import {
  useColorMode,
  Button,
  Flex,
  Box
} from '@chakra-ui/react'
import NextLink from 'next/link'
import styled from '@emotion/styled'

const Container = ({ children }) => {
  const { colorMode } = useColorMode();

  const bgColor = {
    light: 'white',
    dark: 'darkGray.900'
  }

  const color = {
    light: 'gray.800',
    dark: 'whiteAlpha.900'
  }

  return (
    <>
    <Flex
        as="main"
        minW={'100%'}
        // justifyContent="center"
        flexDirection="column"
        bg={bgColor[colorMode]}
        color={color[colorMode]}
        // px={[0, 2, 4, 4]}
        // mt={[0, 4, 8, 8]}
    >
        {children}
    </Flex>
    </>
  )
}

export default Container