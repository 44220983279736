import {
Alert,
AlertIcon,
AlertTitle,
AlertDescription,
CloseButton,
useDisclosure,
Box
} from '@chakra-ui/react'
import Container from './Container';

export default function DemoAccountInfo(props) {

    const {
    isOpen: isVisible,
    onClose,
    onOpen,
    } = useDisclosure({ defaultIsOpen: true })
return (
        <Container>
    <Alert status='info'>
    <AlertIcon />
    <Box>
    <AlertTitle>Demo View</AlertTitle>
    <AlertDescription>
        You're experiencing Biocodify with the demo account
    </AlertDescription>
    </Box>
    </Alert>
    </Container>
    )
}